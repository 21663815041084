<template>
  <div class="flow">
    <PageHeader />
    <div class="header">
      <Header />
      <div class="header-text">
        <div class="header-title">
          流量价值  
        </div>
        <div class="header-sign">
          激励式、嵌入式、联运式多种合作方式！
        </div>
      </div>
    </div>
    <div class="content">
      <div class="section">
        <div class="section-info">
          <div class="info-num">
            01
          </div>
          <div class="info-title">
            激励式
          </div>
          <img
            src="../img/flow-line.png"
            alt=""
            class="info-line"
          >
          <div class="info-text">
            <div class="info-text-title">
              合作方式：
            </div>
            <div class="info-text-desc">
              SDK
            </div>
          </div>
          <div class="info-text">
            <div class="info-text-title">
              优势：
            </div>
            <div class="info-text-desc">
              行业首创流量价值模式，广告模式轻，适合大部分变现场景；
              海量广告，精准推送，让流量高效变现。
            </div>
          </div>
        </div>

        <div class="section-imgs">
          <img
            src="../img/flow-img3-1.png"
            alt=""
          >
          <img
            src="../img/flow-img3-2.png"
            alt=""
          >
          <img
            src="../img/flow-img3-3.png"
            alt=""
          >
        </div>
      </div>
      <div class="section">
        <div class="section-imgs">
          <img
            src="../img/flow-img1-4.png"
            alt=""
          >
          <img
            src="../img/flow-img1-5.png"
            alt=""
          >
          <img
            src="../img/flow-img1-6.png"
            alt=""
          >
        </div>
        <div class="section-info">
          <div class="info-num">
            02
          </div>
          <div class="info-title">
            嵌入式
          </div>

          <img
            src="../img/flow-line.png"
            alt=""
            class="info-line"
          >
          <div class="info-text">
            <div class="info-text-title">
              合作方式：
            </div>
            <div class="info-text-desc">
              SDK、H5、API
            </div>
          </div>
          <div class="info-text">
            <div class="info-text-title">
              优势：
            </div>
            <div class="info-text-desc">
              广告形式经高效优化，游戏介绍清晰，游戏激励提升用户粘性。上线100+CPL任务，变现任务品种多、易完成。
            </div>
          </div>
          <div class="info-icon-box">
            <div class="info-icon-title">
              成功合作案例：
            </div>
            <div class="info-icon-list">
              <div class="info-icon-item">
                <img
                  src="../img/flow-icon1-1.png"
                  alt=""
                >
                <div>魔急便</div>
              </div>
              <div class="info-icon-item">
                <img
                  src="../img/flow-icon1-2.png"
                  alt=""
                >
                <div>多点试玩</div>
              </div>
              <div class="info-icon-item">
                <img
                  src="../img/flow-icon1-5.png"
                  alt=""
                >
                <div>惠头条</div>
              </div>
              <div class="info-icon-item">
                <img
                  src="../img/flow-icon1-6.png"
                  alt=""
                >
                <div>爱盈利</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-info">
          <div class="info-num">
            03
          </div>
          <div class="info-title">
            联运式
          </div>
          <img
            src="../img/flow-line.png"
            alt=""
            class="info-line"
          >
          <div class="info-text">
            <div class="info-text-title">
              联运方式：
            </div>
            <div class="info-text-desc">
              新量象团队提供产品设计技术，运营维护、商务服务等一整套开发运营服务。
            </div>
          </div>
          <div class="info-text">
            <div class="info-text-title">
              优势：
            </div>
            <div class="info-text-desc">
              联运式合作更加贴合媒体合作方的平台定位，媒体方只需进行渠道推广、主题设定即可，无需另外成立团队进行运营。
            </div>
          </div>
          <div class="info-icon-box">
            <div class="info-icon-title">
              成功合作案例：
            </div>
            <div class="info-icon-list">
              <div class="info-icon-item">
                <img
                  src="../img/flow-icon2-1.png"
                  alt=""
                >
                <div>巴菲特手赚</div>
              </div>
              <div class="info-icon-item">
                <img
                  src="../img/flow-icon2-2.png"
                  alt=""
                >
                <div>PP视频</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-imgs">
          <img
            src="../img/flow-img2-1.png"
            alt=""
          >
          <img
            src="../img/flow-img2-2.png"
            alt=""
          >
          <img
            src="../img/flow-img2-3.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="join-box">
      <div class="join-title">
        流量价值  零成本  高收益
      </div>
      <div class="join-desc">
        新量象流量价值平台是团队将公司旗下产品核心技术及广告数据库整合成为一个开放引擎，可以便捷接入流量方，轻松为流量主实现流量高收益变现。
      </div>
      <div
        class="join-btn"
        @click="handleClickDialog"
      >
        让流量价值
      </div>
    </div>
    <div class="footer-content">
      <PageFooter />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import { mapState } from 'vuex'

export default {
  components: {
    Header,
    PageHeader,
    PageFooter
  },
  computed: {
    ...mapState(['showDialog']),
  },
  methods: {
    handleClickDialog() {
      if (!this.showDialog) {
        this.$store.commit('changeDialog', true)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  width: 100%;
  height: 350px;
  background-image: url('../img/flow-bg.png');
  background-size: cover;
}
.header-text{
  position: absolute;
  top: 166px;
  left: 260px;
}
.header-title{
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  font-family: 'fzqk';
}
.header-sign{
  font-size: 24px;
  line-height: 24px;
  color: #fff;
}
.content{
  padding: 0 300px;
}
.footer-content{
  padding: 0 250px;
}
.section{
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 140px;
  &-info{
    .info-num{
      font-size:50px;
      height: 50px;
      line-height: 50px;
      color:rgba(254,233,203,1);
    }
    .info-title{
      font-size:56px;
      // font-family:'fzqk';
      height: 55px;
      line-height: 55px;
      color:rgba(102,102,102,1);
      margin-bottom: 22px;
      margin-top: -25px;
    }
    .info-line{
      width: 45px;
      height: 8px;
      margin-bottom: 25px;
    }
    .info-text{
      display: flex;
      margin-bottom: 35px;
      align-items: flex-start;
      &-title{
        color: #FF8400;
        font-size: 18px;
        line-height: 18px;
        margin-top: 15px;
      }
      &-desc{
        font-size:18px;
        color:#666666;
        line-height:48px;
        max-width: 456px;
      }
    }
    .info-icon-title{
      font-size:18px;
      color:rgba(255,132,0,1);
      line-height:18px;
      margin-bottom: 30px;
    }
    .info-icon-list{
      display: flex;
      align-items: center;
    }
    .info-icon-item{
      margin-right: 40px;
      font-size:14px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color:rgba(102,102,102,1);
      img{
        width: 56px;
        height: 56px;
        margin-bottom: 7px;
      }
    }
  }
  &-imgs{
    display: flex;
    img{
      width: 225px;
      height: 400px;
    }
    img:nth-child(2){
      margin: -30px -18px 0;
    }
  }
}
.join-box{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height:480px;
  background:linear-gradient(-45deg,rgba(255,96,0,1),rgba(255,144,0,1));
  margin-bottom: 60px;
  box-sizing: border-box;
  padding-top: 83px;
  .join-title{
    height:50px;
    line-height: 50px;
    font-size:50px;
    font-family: 'fzqk';
    font-weight:bold;
    color:rgba(255,255,255,1);
    margin-bottom: 45px;
  }
  .join-desc{
    font-size:22px;
    color:rgba(255,255,255,1);
    line-height:48px;
    margin-bottom: 47px;
    text-align: center;
  }
  .join-btn{
    width:300px;
    height:68px;
    background:rgba(255,255,255,0.15);
    border:2px solid rgba(255,255,255,1);
    border-radius:34px;
    font-size:30px;
    // font-family:'fzqk';
    font-weight:bold;
    text-align: center;
    line-height: 68px;
    cursor: pointer;
    color:rgba(255,255,255,1);
  }
}
@media screen and (max-width: 1440px) {
  .header{
    height: 285px;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
  .content{
    padding: 0 160px;
  }
  .footer-content{
    padding: 0 160px;
  }
  .section{
    padding-top: 77px;
    margin-bottom: 103px;
    &-info{
      .info-num{
        font-size:38px;
        height: 38px;
        line-height: 38px;
      }
      .info-title{
        font-size:42px;
        height: 42px;
        line-height: 42px;
        margin-bottom: 16px;
        margin-top: -20px;
      }
      .info-line{
        width: 34px;
        height: 5px;
        margin-bottom: 19px;
      }
      .info-text{
        margin-bottom: 24px;
        &-title{
          font-size: 14px;
          line-height: 14px;
          margin-top: 11px;
        }
        &-desc{
          max-width: 353px;
          font-size:14px;
          line-height:36px;
        }
      }
      .info-icon-title{
        font-size:14px;
        line-height:14px;
        margin-bottom:23px;
      }
      .info-icon-item{
        margin-right: 30px;
        font-size:12px;
        line-height: 12px;
        img{
          width: 42px;
          height: 42px;
          margin-bottom: 7px;
        }
      }
    }
    &-imgs{
      display: flex;
      img{
        width: 170px;
        height: 302px;
      }
      img:nth-child(2){
        margin: -22px -13px 0;
      }
    }
  }
  .join-box{
    height:360px;
    margin-bottom: 44px;
    padding-top: 62px;
    .join-title{
      height:38px;
      line-height: 38px;
      font-size:38px;
      margin-bottom: 33px;
    }
    .join-desc{
      font-size:16px;
      line-height:36px;
    }
    .join-btn{
      width:222px;
      height:50px;
      background:rgba(255,255,255,0.15);
      border:2px solid rgba(255,255,255,1);
      border-radius:25px;
      font-size:22px;
      line-height: 50px;
    }
  }
}
@media screen and (min-width: 1700px){
  .content{
    padding: 0 200px;
  }
  .footer-content{
    padding: 0 200px;
  }
}
</style>
